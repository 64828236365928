<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-top justify-left fill-height>
        <v-flex xs3 lg2 xl2>
          <v-list dense style="background:transparent;">
            <template v-for="(item, i) in items">
              <v-layout v-if="item.heading && checkRoute(item)" :key="i" row align-center>
                <v-flex xs12>
                  <v-subheader v-if="item.heading">
                    {{ item.heading }}
                  </v-subheader>
                </v-flex>
              </v-layout>
              <v-divider v-else-if="item.divider" :key="i" dark class="my-3"></v-divider>
              <v-list-tile v-else-if="checkRoute(item)" :key="i" @click="goRoute(item.route)">
                <v-list-tile-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title class="grey--text">
                    {{ item.text }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-flex>
        <v-flex xs9 lg10 xl10>
          <router-view :config="config" :user="user" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { GET_CONFIG } from '../../config'

export default {
  name: 'Setting',
  data() {
    return {
      config: [],
      dateContest: null,
      activeContest: 0,
      items: [
        { heading: 'Informes' },
        { icon: 'settings', text: 'Encuestas', route: 'statisticsPolls' },
        { icon: 'settings', text: 'Cupones enviados', route: 'statisticsDateImportant' },
        { icon: 'settings', text: 'Pedidos consolidados', route: 'statisticsOrderDate' },
        { icon: 'settings', text: 'Encuestas enviadas', route: 'statisticsPollSendStatics' },
        { icon: 'settings', text: 'Cupones de descuento', route: 'discountCoupons' },
        { divider: true }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getConf()
  },
  methods: {
    checkRoute(item) {
      if (item.route === 'settingsWhatsApp' && this.user.role.id !== 2) {
        return false
      }
      return true
    },
    goRoute(nameRoute) {
      this.$router.push({ name: nameRoute })
    },
    getConf() {
      this.$http({
        method: 'post',
        url: GET_CONFIG,
        params: {
          p: 'getConf',
          shopID: this.user.shopID
        }
      }).then(res => {
        this.config = res.data.json
      })
    }
  }
}
</script>
